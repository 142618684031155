import * as React from 'react';
import image from "../../images/about/Group-2@3x.png";
import "./About.scss";

export const About = () => {
  return (
    <div className='about'>
      <div className='about__content'>
        <h1>About us</h1>
        <p>The Ontario College of Family Physicians represents more than 15,000 family physicians across the province. We support our members by providing evidence-based education and professional development and we promote and recognize leadership excellence in family medicine. We also advocate for the vital role family physicians play in delivering the highest quality care to patients and families across Ontario.</p>
        <p>A not-for-profit and voluntary organization, the Ontario College of Family Physicians was established in 1954 and is the provincial chapter of the College of Family Physicians of Canada.  </p>
        <p><a href="https://www.ontariofamilyphysicians.ca" target="_blank">www.ontariofamilyphysicians.ca </a></p>
      </div>
      <div className='about__image'>
        <img src={image}/>
      </div>
    </div>
  )
}