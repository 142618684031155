import * as React from 'react';
import { About } from '../components/about/About';
import { TakeAction } from '../components/about/TakeAction';

const AboutPage = () => { 
  return (
    <>
      <About />
      <TakeAction flag={'--purple'}/>
    </>
  );
};

export default AboutPage;